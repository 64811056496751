import 'react-tabs/style/react-tabs.css';
import './workSection.css'

import React, { useState } from "react"
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

//import ExternalLink from "../ExternalLink/externalLink"
//import { imageBaseUrl } from '../../_helpers/constant'


const WorkSection = () => {
  const [active, setActive] = useState("B2B");

  const handleTabChange = (data) => {
    console.log('dataaaa:: ',data)
    if(data === "B2B"){
      setActive("B2B")
    }
    if(data === "AI"){
      setActive("AI")
    }
    if(data === "CRM"){
      setActive("CRM")
    }
  }

  return (
    <section className="section techno-section " id="section2">
      <div className="inner-techno-section d-flex align-items-center justify-content-center">
        <div className="row mob-service">
        <div className="col-12 tab-type2">
          <div className="container tech-col d-flex align-items-center justify-content-center">
             <div className="row">
                  <div className="col-12">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center techno-figure align-self-center ">
                          <figure>
                            <StaticImage src='../../images/chatbot.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center techno-figure align-self-center ">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>Conversational AI</span>
                              </h3>
                            </div>
                            <p>
                              <b>Artificial Intelligence Chatbot Platform</b>
                            </p>
                            <a
                              href="/ai-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know More
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
          </div>
          <div className="col-12 tab-type1">
          <div className="container tech-col d-flex align-items-center justify-content-center">
          <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/b2b-1.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>B2B ECommerce</span>
                              </h3>
                            </div>
                            <p>
                              <b>Powerful B2B eCommerce Solution With App</b>
                            </p>
                            <a
                              href="/b2b_ecommerce/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          </div>
         
          <div className="col tab-type3">
          <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div
                          className="col-5 col-sm-5 col-md-12 col-lg-6 
                       techno-figure align-self-center"
                        >
                          <figure className>
                          {/* <img src="images/voice_call.jpg" alt="mobile-app" /> */}
                          <StaticImage src='../../images/voice_call.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>Industry-Centric Mobile AppServices</span>
                              </h3>
                            </div>
                            <p>
                              <b>RPA Services</b>
                            </p>
                            <a
                              href="/rpa-services"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col tab-type4">
          <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/crm-erp-1.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>CRM &amp; ERP</span>
                              </h3>
                            </div>
                            <p>
                              <b>Accounting Seed Customized</b>
                            </p>
                            <a
                              href="/crm-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        {/* <Tabs id="tabs">
          <TabList className="nav nav-tabs nav-fill" >
            <Tab className="nav-item nav-link d-flex align-items-center justify-content-center">B2B ECommerce</Tab>
            <Tab className="nav-item nav-link">Conversational AI</Tab>
            <Tab disabled className="nav-item nav-link">Industry-Centric Mobile App</Tab>
            <Tab className="nav-item nav-link d-flex align-items-center justify-content-center">CRM &amp; ERP</Tab>
          </TabList>
          <div className="tab-content tc">
            <TabPanel className="tab-type1">
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/b2b-1.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>B2B ECommerce</span>
                              </h3>
                            </div>
                            <p>
                              <b>Powerful B2B eCommerce Solution With App</b>
                            </p>
                            <a
                              href="/b2b_ecommerce/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
            <TabPanel className="tab-type2">
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center techno-figure align-self-center ">
                          <figure>
                            <StaticImage src='../../images/chatbot-desktop.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center techno-figure align-self-center ">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>Conversational AI</span>
                              </h3>
                            </div>
                            <p>
                              <b>Artificial Intelligence Chatbot Platform</b>
                            </p>
                            <a
                              href="/ai-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know More
                            </a>
                            <div className="chatbots-bar-bottom">
                              <ul className="d-flex justify-content-end">
                                <li>
                                  <div className="wp-block-columns">
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/crm-implementation-that-improved-the-customer-relations/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          CRM Implementation that improved the
                                          Customer Relations
                                        </a>
                                      </p>
                                    </div>
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/mws-enabled-solutions/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          MWS-enabled Solution for E-Business
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
            <TabPanel className="tab-type3">

            </TabPanel>
            <TabPanel className="tab-type4">
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/crm-erp-1-desktop.png' alt="crm-erp" />
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>CRM &amp; ERP</span>
                              </h3>
                            </div>
                            <p>
                              <b>Accounting Seed Customized</b>
                            </p>
                            <a
                              href="/crm-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
          </div>
        </Tabs> */}


        <div id="tabs">
          <div className="nav nav-tabs nav-fill" >
            <div className="nav-item nav-link d-flex align-items-center justify-content-center" onClick={(e) => handleTabChange('B2B')}>B2B ECommerce</div>
            <div className="nav-item nav-link" onClick={(e) => handleTabChange('AI')}>Conversational AI</div>
            <div disabled className="nav-item nav-link">Industry-Centric Mobile App</div>
            <div className="nav-item nav-link d-flex align-items-center justify-content-center" onClick={(e) => handleTabChange('CRM')}>CRM &amp; ERP</div>
          </div>
       
          <div className="tab-content tc">
            <div className="tab-type1" style={{display : active === "B2B" ? 'block' : 'none'}}>
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/b2b-1.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>B2B ECommerce</span>
                              </h3>
                            </div>
                            <p>
                              <b>Powerful B2B eCommerce Solution With App</b>
                            </p>
                            <a
                              href="/b2b_ecommerce/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                            <div className="chatbots-bar-bottom">
                              <ul className="d-flex justify-content-end">
                                <li>
                                  <div className="wp-block-columns">
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/erp-system-application-to-manage-complete-operations/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          ERP System Application to Manage Complete Operations
                                        </a>
                                      </p>
                                    </div>
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/cloud-enabled-automated-video-editing-and-running-script/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          Cloud enabled Automated Video Editing and Running Script
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-type2" style={{display : active === "AI" ? 'block' : 'none'}}>
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center techno-figure align-self-center ">
                          <figure>
                            <StaticImage src='../../images/chatbot-desktop.png' alt=""/>
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center techno-figure align-self-center ">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>Conversational AI</span>
                              </h3>
                            </div>
                            <p>
                              <b>Artificial Intelligence Chatbot Platform</b>
                            </p>
                            <a
                              href="/ai-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know More
                            </a>
                            <div className="chatbots-bar-bottom">
                              <ul className="d-flex justify-content-end">
                                <li>
                                  <div className="wp-block-columns">
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/crm-implementation-that-improved-the-customer-relations/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          CRM Implementation that improved the
                                          Customer Relations
                                        </a>
                                      </p>
                                    </div>
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/mws-enabled-solutions/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          MWS-enabled Solution for E-Business
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className="tab-type4" style={{display : active === "CRM" ? 'block' : 'none'}}>
              <div className="container tech-col d-flex align-items-center justify-content-center">
                <div className="row">
                  <div className="col-12 ">
                    <div className="item-inner">
                      <ul className="lines">
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#">&nbsp;</Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            &nbsp;
                          </Link>
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-12 col-lg-6 techno-figure align-self-center">
                          <figure className>
                            <StaticImage src='../../images/crm-erp-1-desktop.png' alt="crm-erp" />
                          </figure>
                        </div>
                        <div className="col-7 col-sm-7 col-md-12 col-lg-6  align-self-center">
                          <div className="techno-cont">
                            <div className="common-title">
                              <h3>
                                <span>CRM &amp; ERP</span>
                              </h3>
                            </div>
                            <p>
                              <b>Accounting Seed Customized</b>
                            </p>
                            <a
                              href="/crm-case-study/"
                              className="btn btn btn-primary know-btn white-btn "
                              target="_blank"
                            >
                              Know more
                            </a>
                            <div className="chatbots-bar-bottom">
                              <ul className="d-flex justify-content-end">
                                <li>
                                  <div className="wp-block-columns">
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/maximum-exposure-online/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          Maximum Exposure Online
                                        </a>
                                      </p>
                                    </div>
                                    <div className="wp-block-column custom-li">
                                      <p>
                                        <a
                                          href="/google-adwords-account-data-fetched-and-saved-into-salesforce/"
                                          target="_blank"
                                          
                                          aria-label=" (opens in a new tab)"
                                        >
                                          Google Adwords Account Data Fetched
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    </section>
  )
}

export default WorkSection
