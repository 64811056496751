import React from "react"
//import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
//import ExternalLink from "../ExternalLink/externalLink"
//import { imageBaseUrl } from '../../_helpers/constant'

const AboutUsSection = () => {
  return (
    <div>
      <section className="section aboutUs-section" id="section5">
        <div className="container">
          {/*- Start Container */}
          <div className="row">
            {/*- Start row */}
            <div className="col-12 col-sm-12">
              <div className="section-heading-title">
                <h3> About us </h3>
              </div>
            </div>
          </div>
          {/*- end row */}
          {/*- Start row */}
          <div className="row">
            <div className="col-12 col-sm-6 aboutUs-bg">
              <div className="aboutUs-cont ">
                <div>
                  <h3>Turning IT into a Strategic Asset</h3>
                  <div className="textwidget custom-html-widget">
                    <p>
                      In today’s technology savvy world, Webuters’ is trusted
                      for its consulting, IT development and Infrastructure
                      services. We have proven experience of building easy to
                      maintain and connect to customer solutions.
                    </p>
                  </div>
                </div>{" "}
              </div>
              <a
               target="_blank"
                href="/about-us/"
                className="btn btn btn-primary know-btn white-btn"
              >
                Know more{" "}
              </a>

            </div>
            <div className="col-12 col-sm-6 aboutUs-bg">
              <div className="aboutUs-fig d-flex justify-content-end">
                <figure>
                  <StaticImage src='https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/about_us.jpg' alt="about_us" />
                </figure>
              </div>
            </div>
          </div>
          {/*- end row */}
        </div>
        {/*- end container */}
      </section>
    </div>
  )
}

export default AboutUsSection

// export const PageQuery = graphql`
// query($id: String!) {
//   wpPage(id: { eq: $id }) {
//     id
//     title
//     uri
//   }
// }
//  `
