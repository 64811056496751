import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

//import { imageBaseUrl } from '../../_helpers/constant'

const Hero = () => (
  <section className="section banner-section" id="section1">
    <div className="widget-wrapper">
      <StaticImage
        src='https://i2.wp.com/cms.webuters.com/wp-content/uploads/2020/02/banner-1.jpg'
        alt="cloud consulting"
        // width={1920}
        // height={730}
      />
    </div>
  </section>
)

export default Hero
