import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
//import { imageBaseUrl } from '../../_helpers/constant'


const CulturesSection = () => {
    
  return (
    <section className="section our-culture-sec" id="section7">
      <div className="container">
        {/*- Start Container */}
        <div className="row">
          {/*- Start Row */}
          <div className="col-12 col-sm-12">
            <div
              className="section-heading-title text-center  wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <h3> culture and values </h3>
              <p>
                {" "}
                Our core values are the lifeblood of Webuters; they reflect who
                we are, support our vision, and define our identity.{" "}
              </p>
            </div>
          </div>
        </div>
        {/*- end row */}
      </div>
      {/*- end container */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <div
              className="viewGallery-block  wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="viewGallery-col-lg viewGallery-col">
                  <Link to="/cultural-values/" target="_blank">
                    <figure>
                      <StaticImage src='https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/gallery.jpg' alt="Culture"/>
                      <figcaption>
                        {" "}
                        View All <i className="icon icon-view"> &nbsp; </i>
                      </figcaption>
                    </figure>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CulturesSection
