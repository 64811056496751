import React, { } from "react"
import Slider from 'react-slick';
//import $ from 'jquery';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const About_us = (props) => {
    const { sliderData, page } = props;
    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <section className='main-service-section customerSay-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h4>CLIENTS</h4>
                        <h2 className='heading__title'>What our customers say</h2>
                        <div>
                            <Slider {...settings}>
                                {sliderData && sliderData.map((tm) => {
                                    const image = getImage(tm.node.featuredImage.node.localFile)
                                    let externallink = JSON.parse(tm.node.externalLinks);
                                    return (
                                        <div key={tm.node.id}>
                                            <div className='testimonials-text'>
                                                <div
                                                    key={page.id}
                                                    dangerouslySetInnerHTML={{
                                                        __html: tm.node.content,
                                                    }}
                                                />
                                            </div>
                                            <div className='testimonial_row'>
                                                <div className='wp-medium-4 wpcolumns'>
                                                    <div className='rtsw-testimonial_avatar'>
                                                        <div className='rtsw-avtar-image'>
                                                            {/* <img
                                                                title='Bill Cohnac'
                                                                src={tm.node.featuredImage.node.sourceUrl}
                                                                className='rtsw-avtar-image'
                                                            /> */}
                                                             <GatsbyImage image={image} alt={tm.node.featuredImage.node.title} className='rtsw-avtar-image'/>
                                                            {/* <noscript><img className="rtsw-avtar-image " src="https://i1.wp.com/uat.webuters.com/wp-content/uploads/2020/01/male-placeholder-image-300x300-1.jpeg?w=580&#038;ssl=1" title="Bill Cohnac" data-recalc-dims="1"></noscript> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='wp-medium-8 wpcolumns'>
                                                    <div className='rtsw-testimonial-author'>
                                                        <div className='rtsw-testimonial-client'>
                                                            <strong>{tm.node.title}</strong>
                                                        </div>
                                                        <div className='rtsw-testimonial-cdec'>
                                                            {externallink._testimonial_job}
                                                        </div>
                                                    </div>
                                                    <div className='rtsw-testimonial-rate'></div>
                                                    <div className='rtsw-social '>
                                                        <ul></ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default About_us

