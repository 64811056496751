import { useStaticQuery, graphql } from "gatsby";

export const useBlogHeaderData = () => {
    const data = useStaticQuery(graphql`{
        news: allWpPost(
        limit: 1
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "blogs"}}}}}
        sort: {fields: databaseId, order: ASC}
        ) {
            edges {
                node {
                    id
                    title
                    uri
                    date(formatString: "DD MMMM YYYY, h:mm a")
                    excerpt
                    featuredImage {
                        node {
                            sourceUrl
                        }
                    }
                    author {
                        node {
                          name
                        }
                      }
                }
            }
           }
        }`
    )
    return data
}