import { useStaticQuery, graphql } from "gatsby";

export const useListBlogsQuery = () => {
  const data = useStaticQuery(graphql`
  query ListBlogs {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Blogs"}}}}, status: {eq: "publish"}}
      limit: 4
      sort: {fields: date, order: DESC}
      ) {
    edges {
      node {
        id
        title
        uri
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
            title
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date(formatString: "DD MMMM YYYY, h:mm a")
      }
    }
  }
  }
  
  `)

  return data;

}
