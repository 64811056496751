import { useStaticQuery, graphql } from "gatsby";

export const useListProductsQuery = () => {
  const data = useStaticQuery(graphql`
  query ListProducts {
  allWpPost(
    filter: {categories: {nodes: {elemMatch: {name: {eq: "Product"}}}}, status: {eq: "publish"}}
    limit: 4
    sort: {fields: databaseId, order: DESC}
  ) {
    edges {
      node {
        id
        title
        uri
        featuredImage {
          node {
            mediaItemUrl
            sourceUrl
          }
        }
        link
      }
    }
  }
}
  
  `)

  return data;

}