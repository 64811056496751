import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../Layout/Layout"

const Case_all_study = (props) => {
    console.log('case all study')
    const { allWpCategory } = props;
    
    const [postArr, setPostArr] = useState()

    
    useEffect(() => {
        let arr = []
        if(allWpCategory && allWpCategory.edges && allWpCategory.edges.length > 0){
            allWpCategory.edges.map((item,i) => {
                if(i === 0){
                    item['node']['isActive'] = true 
                }else{
                    item['node']['isActive'] = false 
                }
                
                if(item.node.name === "All Case Studies"){
                    arr.push(item.node.posts.nodes)
                }
            })
            setPostArr(arr[0])
        }
    },[])
    // console.log('allWpCategory dataa:: ',allWpCategory)
    // console.log('postArr:: ',postArr)
    const handleClickCategory = (data, index) => {
        console.log('handleClickCategory', [data, index])
        if(allWpCategory && allWpCategory.edges && allWpCategory.edges.length > 0){
            allWpCategory.edges.map((item,i) => {
                if(i === index){
                    item['node']['isActive'] = true 
                }else{
                    item['node']['isActive'] = false 
                }
            })
        }
        setPostArr(data.node.posts.nodes)
      
    }
    return (
            
            <section className="caseStudiesTab-sec">
                {/*- Start Container */}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {
                                    allWpCategory && allWpCategory.edges && allWpCategory.edges.map((category,i) => {
                                        return(
                                            <li className="nav-item" key={i}>
                                                <a className={category && category.node && category.node.isActive ? 'nav-link active' : 'nav-link'}  id="casestudy-tab-146" data-toggle="tab" onClick={() => handleClickCategory(category,i)} role="tab" aria-controls={146} aria-selected="false">{category.node.name} </a>
                                            </li>
                                        )
                                    })
                                }
                                
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="case-study147" role="tabpanel" aria-labelledby="casestudy-tab-147">
                                    {
                                        postArr && postArr.length > 0 && postArr.map((item,i) => {
                                            if(i % 2 == 0){
                                                return(
                                                    <div className="tab-row" key={i}>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-4 col-md-4 align-self-center caseStudies-fig">
                                                                <figure>
                                                                    <img src={item && item.featuredImage && item.featuredImage.node && item.featuredImage.node.sourceUrl} />
                                                                    
                                                                </figure>
                                                            </div>
                                                            <div className="col-12 col-sm-8 col-md-8 align-self-center">
                                                                <div className="caseStudies-cont">
                                                                    <h3>{item.title}</h3>
                                                                    <p></p>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: item.excerpt }}
                                                                    />
                                                                    
                                                                    <p />
                                                                    <div className="d-flex justify-content-end">
                                                                        <a href={item.uri} className="btn btn btn-primary know-btn white-btn">Read Case
                                            Study</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }else{
                                                return(
                                                    <div className="tab-row tab-row-inverse" key={i}>
                                                        <div className="row flex-mobile-column-reverse">
                                                            <div className="col-12 col-sm-8 col-md-8 align-self-center">
                                                                <div className="caseStudies-cont">
                                                                <h3>{item.title}</h3>
                                                                    <p> </p>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: item.excerpt }}
                                                                    />
                                                                    {/* <p>Intelligent bots have already made their way into the world of business. They benefit the businesses at several levels, our client came with a requirement to make its already existing Chabot platform efficient and intelligent.</p> */}
                                                                    <p />
                                                                    <div className="d-flex justify-content-start">
                                                                        <a href={item.uri} className="btn btn btn-primary know-btn case-stud-btn">Read
                                                                        Case
                                            Study</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4 col-md-4 align-self-center caseStudies-fig">
                                                            <figure>
                                                                    <img src={item && item.featuredImage && item.featuredImage.node && item.featuredImage.node.sourceUrl} />
                                                                    
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    
                                    
                                    
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        
    )
}


export default Case_all_study

