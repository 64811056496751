import { useStaticQuery, graphql } from "gatsby";

export const useCategoryList = () => {
    const data = useStaticQuery(graphql`
        query ListCategory {
            allWpCategory(
                filter: {parentDatabaseId: {eq: 145}}
                sort: {order: ASC, fields: databaseId}
            ) {
                edges {
                node {
                    id
                    name
                    databaseId
                    
                    posts {
                    nodes {
                        excerpt
                        id
                        title
                        uri
                        featuredImage {
                        node {
                            sourceUrl
                        }
                        }
                    }
                    }
                }
                }
            }
        
        }`
    )
    return data
}