import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import { useListServicesQuery } from "../../hooks/useListServicesQuery"
//import ExternalLink from "../ExternalLink/externalLink"

const ServicesSection = () => {
  const { allWpPost } = useListServicesQuery();
  const listServices = allWpPost.edges
  //console.log('listServices:: ',listServices)

  return (
    <section className="section our-services-sec position-relative" id="section4">
      <StaticImage className="service-bg" src="../../images/service-bg.jpg" alt=""/>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12">
            <div className="section-heading-title">
              <h3> Services </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row plr-5">
          {listServices.map((listService) => {
            const serviceHtml = listService.node.excerpt
            return (
              <div key={listService.node.id} className="col" style={{ minWidth: "17%" }}>
                <a href={listService.node.slug} target="_blank">
                  <div className="service-box">
                    <div className="inner-service-box ellipsis" dangerouslySetInnerHTML={{ __html: serviceHtml }} />
                  </div>
                </a>
              </div>
            )
          })}

        </div>
      </div>
    </section>
  )
}

export default ServicesSection
