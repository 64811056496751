import React from "react"
import { StaticImage } from 'gatsby-plugin-image'

//import { imageBaseUrl } from '../../_helpers/constant'

const CareerSection = () => {
    // const url = imageBaseUrl + '/themes/webi-2020/assets/images/career.jpg' //'https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/career.jpg'
    // console.log('urlllllll:: ',url)
  return (
    <section className="section career-section" id="section6">
      <div className="container">
        {/*- Start Container */}
        <div className="row">
          {/*- Start row */}
          <div className="col-12 col-sm-12">
            <div className="section-heading-title">
              <h3> Careers </h3>
            </div>
          </div>
        </div>
        {/*- end row */}
      </div>
      {/*- end container */}
      <div className="career-block">
        <figure>
          {/* <img src={imageBaseUrl + '/themes/webi-2020/assets/images/career.jpg'} alt="career" /> */}
          {/* <amp-img src-set="https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/career.jpg" src="https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/career.jpg" width="" height="" layout="responsive" /> */}

          <StaticImage src='https://i2.wp.com/cms.webuters.com/wp-content/themes/webi-2020/assets/images/career.jpg' alt=""/>
        </figure>
        <div className="container">
          {/*- Start Container */}
          <div className="row">
            {/*- Start row */}
            <div className="col-12 col-sm-12">
              <div className="white-bg">&nbsp;</div>
              <div className="career-oppr-box">
                <h3>
                  opportunities <span>right</span> for you{" "}
                </h3>
                <a
                  href="/career/"
                  className="btn btn btn-primary know-btn white-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  EXPLORE CAREERS
                </a>
              </div>
            </div>
          </div>
          {/*- end row */}
        </div>
        {/*- end container */}
      </div>
    </section>
  )
}

export default CareerSection
