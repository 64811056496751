import React, { useState, useEffect, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Hero from '../components/Hero/Hero';


import WorkSection from '../components/WorkSection/WorkSection';
import ProductsSection from '../components/ProductsSection/ProductSection';
import ServicesSection from '../components/ServicesSection/ServiceSection';
import AboutUsSection from '../components/AboutUsSection/AboutUsSection';
import CareerSection from '../components/CareerSection/CareerSection';
import CulturesSection from '../components/CulturesSection/CulturesSection';
import BlogSection from '../components/BlogSection/BlogSection';
import TalkToUsSection from '../components/TalkToUsSection/TalkToUsSection';

import SEARCHCOMPONENT from '../components/BlogSection/SearchComponet';
//import components
import Layout from '../components/Layout/Layout';

import Case_all_study from '../components/CaseStudy/case-study'
import About_us from '../components/About/about_us'

import Pagination from '../_helpers/pagination';

import { useBlogHeaderData } from '../hooks/useLatestBlogQuery';
import { useRightSideBlog } from '../hooks/usePopularBlogQuery';
import { useCategoryList } from '../hooks/useGetCategoryListQuery';
//import { useCaseStudyList } from '../hooks/useGetCategoryListQuery';
import _ from 'lodash'
import {
    pageSize
} from '../_helpers/constant';

import $ from 'jquery';

const PageTemplate = ({ data }) => {
    //console.log('wp link page')
    const { page, allWpPost } = data;
    //console.log('dataaaaaa:::-- ', data)
    let search = undefined;
    if (typeof window !== 'undefined') {
        search = window.location.search;
    } else {
        search = '';
    }
    // console.log('searchhh:: ', search);
    let searchData = [];

    const { news } = useBlogHeaderData();
    const { popularBlog } = useRightSideBlog();
    const { allWpCategory } = useCategoryList();

    const [activePage, setActivePage] = useState(1);

    var blogPostWithoutNullContent = allWpPost.edges
    // var blogPostWithoutNullContent = _.reject(allWpPost.edges, ['node.content', null]);
    //  console.log('blogPostWithoutNullContent: ' , blogPostWithoutNullContent);
    const filterBy = (term) => {
        const escapeRegExp = (str) => str.replace('[-A-Z0-9+&@#/%?=~_|!:,.;]');

        const re = new RegExp(escapeRegExp(term), 'i');
        return (person) => {
            for (let prop in person.node) {
                if (!person.node.hasOwnProperty(prop)) {
                    continue;
                }
                if (prop === 'title') {
                    if (re.test(person.node[prop])) {
                        return true;
                    }
                }
            }
            return false;
        };
    };
    let text = '';
    if (search !== '' && search !== null && search !== undefined) {
        text = search.split('=');
        text = text[1];
        text = text.replace(/[^a-zA-Z ]/g, ' ');
        const value = blogPostWithoutNullContent.filter(filterBy(text));
        searchData = value;
    }
    //searchData = [allWpPost.edges[0]]
    //console.log('search Data:: ', searchData)
    const checkScrollPosition = () => {
        var navBlock = $('.dot-navigation-block');
        var scrollPos = $(document).scrollTop();
        navBlock.find('a').each(function () {
            // If window was move over block positon
            var block = $($(this).attr('href'));
            if (
                block.position().top <= scrollPos + 5 &&
                block.position().top + block.height() > scrollPos + 5
            ) {
                // remove all active class and add active to the only one we want
                navBlock.find('li').removeClass('active');
                $(this).parent('li').addClass('active');
            } else {
                $(this).parent('li').removeClass('active');
            }
        });
    };
    useEffect(() => {
        $(document).ready(function () {
            var navBlock = $('.dot-navigation-block');
            navBlock.find('a').on('click', function (e) {
                e.preventDefault();
                $(document).off('scroll');
                // Get block distance from top of browser
                var block = $(this).attr('href');
                var blockPosition = $(block).offset().top + 20;
                // Animate window to that position
                $('html, body').animate(
                    {
                        scrollTop: blockPosition,
                    },
                    600,
                    'swing',
                    function () {
                        checkScrollPosition();
                    }
                );
            });
        });
        $(window).on('scroll', function () {
            checkScrollPosition();
        });
        $('.about__img').addClass('animate-img')
    }, []);

    
    const currentBlogData = useMemo(() => {
        const firstPageIndex = (activePage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return blogPostWithoutNullContent.slice(firstPageIndex, lastPageIndex);
    }, [activePage]);
    // console.log('currentBlogData:: ', currentBlogData);

    const testimonial = data.allWpEasytestimonial.edges;
    //console.log('testimonial', testimonial);
    let sliderData = [];
    if (testimonial.length > 0) {
        testimonial.map((tm) => {
            if (tm.node.content !== null) {
                sliderData.push(tm);
            }
        });
    }
    // let externallink = JSON.parse(sliderData)
    //sliderData = JSON.parse(sliderData)
    // console.log('sliderData:: ', sliderData);

    

    return page.uri === '/' ? (
        <>
            <Layout>
                <div className='dot-navigation-block'>
                    <ul id='menu' className='_list'>
                        <div className='menu-side-menu-container'>
                            <ul id='menu-side-menu' className='menu'>
                                <li
                                    id='menu-item-6356'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6356'
                                >
                                    <a href='#section1'>Home</a>
                                </li>
                                <li
                                    id='menu-item-6357'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6357'
                                >
                                    <a href='#section2'>Case Studies</a>
                                </li>
                                <li
                                    id='menu-item-6358'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6358'
                                >
                                    <a href='#section3'>Our Products</a>
                                </li>
                                <li
                                    id='menu-item-6359'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6359 active'
                                >
                                    <a href='#section4'>Services</a>
                                </li>
                                <li
                                    id='menu-item-6360'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6360'
                                >
                                    <a href='#section5'>About Us</a>
                                </li>
                                <li
                                    id='menu-item-6361'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6361'
                                >
                                    <a href='#section6'>Careers</a>
                                </li>
                                <li
                                    id='menu-item-6362'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6362'
                                >
                                    <a href='#section7'>Culture and values</a>
                                </li>
                                <li
                                    id='menu-item-6363'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6363'
                                >
                                    <a href='#section8'>Blog</a>
                                </li>
                                <li
                                    id='menu-item-6364'
                                    className='menu-item menu-item-type-custom menu-item-object-custom menu-item-6364'
                                >
                                    <a href='#section9'>Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
                <Seo post={page} />
                <Hero />
                <WorkSection />
                <ProductsSection />
                <ServicesSection />
                <AboutUsSection />
                <CareerSection />
                <CulturesSection />
                <BlogSection />
                <TalkToUsSection />
            </Layout>
        </>
    ) : (
            <Layout>
                <Seo post={page} />
                <Helmet htmlAttributes={{
                    lang: 'en',
                }}>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/css/ionicons.min.css" />
                </Helmet>
                <section
                    className='pageHeader'
                    style={{
                        background:
                            page.featuredImage != null
                                ? `url(${page.featuredImage.node.sourceUrl})`
                                : '#d67373',
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                {page.uri === '/thankyou/' ? (
                                    <p>Form Submitted Successfully</p>
                                ) : search !== '' && search !== null && search !== undefined ? (
                                    <nav>
                                        <ol className='breadcrumb'>
                                            <li className='breadcrumb-item'>
                                                <Link to='/'>Home</Link>
                                            </li>
                                            <li className='breadcrumb-item'>
                                                <Link to='/blogs/'>Webuters Blog</Link>
                                            </li>
                                            <li className='breadcrumb-item active'>Search</li>
                                        </ol>
                                    </nav>
                                ) : (
                                            <nav>
                                                <ol className='breadcrumb'>
                                                    <li className='breadcrumb-item'>
                                                        <Link to='/'>Home</Link>
                                                    </li>
                                                    <li className='breadcrumb-item active'>{page.title}</li>
                                                </ol>
                                            </nav>
                                        )}

                                {/* <h1 className='pagetitle__heading'>{page.title}</h1>
                                <br />
                                {page.uri === '/blogs/' &&
                                    search !== '' &&
                                    search !== undefined ? (
                                        <div>
                                            <h6 className='pagetitle__heading'>Search::: {text}</h6>
                                        </div>
                                        
                                    ) : null} */}
                                    <h1 className='pagetitle__heading'>
                {search !== '' && search !== undefined ? null : page.title}

                {page.uri === '/blogs/' &&
                search !== '' &&
                search !== undefined ? (
                  <span className='entry-header-inner'>Search: "{text}"</span>
                ) : null}
              </h1>

                            </div>
                        </div>
                    </div>
                </section>
                {
                    page.uri === "/career/" || page.uri === "/case-study-list/" || page.uri === "/blogs/" || page.uri === "/ai-case-study/" || page.uri === "/cultural-values/" || page.uri === "/crm-case-study/" ?
                        null
                        :
                        <div className='postWrapper'>
                            <div className='postContentWrapper'>
                                <div className='postContent'>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: page.content }}
                                    />
                                </div>
                            </div>
                        </div>
                }

                {page.uri === '/career/' ? (
                    <>
                        <div className='container pt-3 mt-3 pt-md-3 mt-md-3'>
                            <div className='row heading pt-md-3 mt-md-3 pt-lg-4 mt-lg-4 mb-40'>
                                <div className='col-sm-12 col-md-6 col-lg-5'>
                                    <img
                                        width='477'
                                        height='500'
                                        alt=''
                                        src={
                                            page.template.careerContent.careerMainContent.image
                                                .sourceUrl
                                        }
                                        className='wp-image-8213 lazyloaded'
                                    />
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-6 offset-lg-1'>
                                    <h2 className='heading__title'>
                                        {page.template.careerContent.careerMainContent.title}
                                    </h2>
                                    <div
                                        key={page.id}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                page.template.careerContent.careerMainContent.description,
                                        }}
                                    />
                                    {/* <p className="heading__desc text-justify" style={{fontWeight: '500 !important'}}>A job at Webuters Technologies is unlike any other you’ve had. You’ll be challenged. You’ll be inspired. You’ll be proud. Because whatever your job is here, you’ll be part of something meaningful.</p>
                        <br />
                        <p className="heading__desc text-justify">We believe that every individual has unique skills and passion that they can bring to Webuters for a rich and rewarding career. And we have a philosophy of hiring for tomorrow, of taking on a candidate not just for what we see in them today, but also assessing their potential to drive the business tomorrow. So, in addition to technical soundness supported by excellent academic records, we look for that spark, and a high degree of passion for work is what differentiates the exceptional from merely competent.</p>
                        <p>&nbsp;</p>
                        <p className="heading__desc text-justify">At Webuters, we try to develop future leaders apart from competent professionals. If you live, eat and breathe digital and willing to work with like-minded people, Take our current opportunities.</p> */}
                                </div>
                            </div>
                        </div>
                        <section
                            id='video1'
                            className='video video-1 bg-overlay bg-parallax counters-white bg-img-bottom'
                            style={{
                                backgroundImage:
                                    'url(https://i1.wp.com/uat.webuters.com/wp-content/uploads/2020/02/3-5.jpg?fit=2048%2C1365&amp;ssl=1)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                            }}
                        >
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='video__btn mb-45'></div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-5 col-xl-5'>
                                        <div className='heading'>
                                            <span className='heading__subtitle color-white'>
                                                {
                                                    page.template.careerContent.bottomBackgroundImage
                                                        .backgroundTitle
                                                }
                                            </span>
                                            <h3 className='heading__title color-white'>
                                                {
                                                    page.template.careerContent.bottomBackgroundImage
                                                        .backgroundDescription
                                                }
                                            </h3>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-7 col-xl-6 offset-xl-1'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'></div>
                                            <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                                <div className='counter-item'>
                                                    <div className='counter__icon'>
                                                        <img
                                                            src={
                                                                page.template.careerContent.bottomBackgroundImage
                                                                    .features.feature1.feature1Image.sourceUrl
                                                            }
                                                            className='lazyloaded'
                                                        />
                                                    </div>
                                                    <p className='counter__desc'>
                                                        {
                                                            page.template.careerContent.bottomBackgroundImage
                                                                .features.feature1.feature1Title
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                                <div className='counter-item'>
                                                    <div className='counter__icon'>
                                                        <img
                                                            src={
                                                                page.template.careerContent.bottomBackgroundImage
                                                                    .features.feature2.feature2Image.sourceUrl
                                                            }
                                                            className='lazyloaded'
                                                        />
                                                    </div>
                                                    <p className='counter__desc'>
                                                        {
                                                            page.template.careerContent.bottomBackgroundImage
                                                                .features.feature2.feature2Title
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                                <div className='counter-item'>
                                                    <div className='counter__icon'>
                                                        <img
                                                            src={
                                                                page.template.careerContent.bottomBackgroundImage
                                                                    .features.feature3.feature3Image.sourceUrl
                                                            }
                                                            className='lazyloaded'
                                                        />
                                                    </div>
                                                    <p className='counter__desc'>
                                                        {
                                                            page.template.careerContent.bottomBackgroundImage
                                                                .features.feature3.feature3Title
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ) : null}

                {page.uri === '/about-us/' ? (
                    <About_us sliderData={sliderData} page={data && data.page}/>
                ) : null}

                {page.uri === '/blogs/' && search === '' && search !== undefined ? (
                    <>
                        <section id='content' className='main-blog-section'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className='row'>
                                            <div className='col-md-12 blog-col'>
                                                <article id='post-3288'>
                                                    <div className='post-image'>
                                                        <a
                                                            href={
                                                                news &&
                                                                news.edges &&
                                                                news.edges[0].node &&
                                                                news.edges[0].node.uri
                                                            }
                                                        >
                                                            <img
                                                                alt=''
                                                                className='img-responsive lazyloaded'
                                                                src={
                                                                    news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.featuredImage.node &&
                                                                    news.edges[0].node.featuredImage.node.sourceUrl
                                                                }
                                                                loading='lazy'
                                                            />

                                                            {/* <img src="https://i1.wp.com/uat.webuters.com/wp-content/uploads/2020/01/main_blog_img-1.jpg?fit=1200%2C346&ssl=1" alt="" className="img-responsive" /> */}
                                                        </a>
                                                    </div>
                                                    <div className='post-heading'>
                                                        <h5 className='blog-heading-title'>
                                                            <a
                                                                href={
                                                                    news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.uri
                                                                }
                                                            >
                                                                {news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.title}
                                                            </a>
                                                        </h5>
                                                        <h2 className='blog-heading-excerpt'>
                                                            <a
                                                                href={
                                                                    news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.uri
                                                                }
                                                            >
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            news &&
                                                                            news.edges &&
                                                                            news.edges[0].node &&
                                                                            news.edges[0].node.excerpt,
                                                                    }}
                                                                ></div>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <p>
                                                        by{' '}
                                                        <span className='author-blog'>
                                                            <a
                                                                href={
                                                                    news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.uri
                                                                }
                                                            >
                                                                {' '}
                                                                {news &&
                                                                    news.edges &&
                                                                    news.edges[0].node &&
                                                                    news.edges[0].node.author.node.name}{' '}
                                                            </a>
                                                        </span>
                                                        <strong className='date-blog'>
                                                            {news &&
                                                                news.edges &&
                                                                news.edges[0].node &&
                                                                news.edges[0].node.date}
                                                        </strong>
                                                    </p>
                                                    <div className='other details'>
                                                        <ul className='meta-post'></ul>
                                                        {/* <!-- <a href="" className="pull-right btn-outline-info btn-sm"> <i className="icon-angle-right"></i></a> --> */}
                                                    </div>
                                                </article>
                                            </div>

                                            {currentBlogData && currentBlogData.length > 0
                                                ? currentBlogData.map((blog, i) => {
                                                    if (i === 0 || i % 2) {
                                                        return (
                                                            <div className='col-md-6 blog-col' key={i}>
                                                                <article id='post-10659'>
                                                                    <div className='post-image'>
                                                                        <a
                                                                            href={blog && blog.node && blog.node.uri}
                                                                        >
                                                                            <img
                                                                                alt=''
                                                                                className='img-responsive lazyloaded'
                                                                                src={
                                                                                    blog.node &&
                                                                                    blog.node.featuredImage &&
                                                                                    blog.node.featuredImage.node &&
                                                                                    blog.node.featuredImage.node.sourceUrl
                                                                                }
                                                                                loading='lazy'
                                                                            />
                                                                            {/* <noscript><img src="https://i1.wp.com/uat.webuters.com/wp-content/uploads/2021/08/cloud-platforms.jpg?fit=1200%2C675&ssl=1" alt="" className="img-responsive" /></noscript> */}
                                                                        </a>
                                                                    </div>
                                                                    <div className='post-heading'>
                                                                        <h5 className='blog-heading-title'>
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                {blog.node.title}
                                                                            </a>
                                                                        </h5>
                                                                        <h2 className='blog-heading-excerpt-sub'>
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                <div
                                                                                    key={i}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: blog.node.excerpt,
                                                                                    }}
                                                                                ></div>
                                                                            </a>
                                                                        </h2>
                                                                    </div>
                                                                    <p></p>
                                                                    <p>
                                                                        by{' '}
                                                                        <span className='author-blog'>
                                                                            {' '}
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                {blog.node.author.node.name}
                                                                            </a>{' '}
                                                                        </span>
                                                                        <strong className='date-blog'>
                                                                            {blog.node.date}
                                                                        </strong>
                                                                    </p>
                                                                    <p></p>
                                                                    <div className='other-details'>
                                                                        <ul className='meta-post'></ul>
                                                                        {/* <!-- <a href="" className="pull-right btn-outline-info btn-sm"> <i className="icon-angle-right"></i></a> --> */}
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className='col-md-6 blog-col' key={i}>
                                                                <article id='post-10650'>
                                                                    <div className='post-image'>
                                                                        <a
                                                                            href={blog && blog.node && blog.node.uri}
                                                                        >
                                                                            <img
                                                                                alt=''
                                                                                className='img-responsive lazyloaded'
                                                                                src={
                                                                                    blog.node &&
                                                                                    blog.node.featuredImage &&
                                                                                    blog.node.featuredImage.node &&
                                                                                    blog.node.featuredImage.node.sourceUrl
                                                                                }
                                                                                loading='lazy'
                                                                            />
                                                                            {/* <noscript><img src="https://i0.wp.com/uat.webuters.com/wp-content/uploads/2021/08/cloud-transformation.jpg?fit=1200%2C675&ssl=1" alt="" className="img-responsive" /></noscript> */}
                                                                        </a>
                                                                    </div>
                                                                    <div className='post-heading'>
                                                                        <h5 className='blog-heading-title'>
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                {blog.node.title}
                                                                            </a>
                                                                        </h5>
                                                                        <h2 className='blog-heading-excerpt-sub'>
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                <div
                                                                                    key={i}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: blog.node.excerpt,
                                                                                    }}
                                                                                ></div>
                                                                            </a>
                                                                        </h2>
                                                                    </div>
                                                                    <p></p>
                                                                    <p>
                                                                        by{' '}
                                                                        <span className='author-blog'>
                                                                            {' '}
                                                                            <a
                                                                                href={
                                                                                    blog && blog.node && blog.node.uri
                                                                                }
                                                                            >
                                                                                {blog.node.author.node.name}
                                                                            </a>{' '}
                                                                        </span>
                                                                        <strong className='date-blog'>
                                                                            {blog.node.date}
                                                                        </strong>
                                                                    </p>
                                                                    <p></p>
                                                                    <div className='other-details'>
                                                                        <ul className='meta-post'></ul>
                                                                        {/* <!-- <a href="" className="pull-right btn-outline-info btn-sm"> <i className="icon-angle-right"></i></a> --> */}
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        );
                                                    }
                                                })
                                                : null}
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <aside id='blog-sidebar' className='sidebar-blog pull-right'>
                                            <SEARCHCOMPONENT allWpPost={allWpPost} />
                                            <div className='popular-posts-sr'>
                                                {popularBlog &&
                                                    popularBlog.edges &&
                                                    popularBlog.edges.length > 0
                                                    ? popularBlog.edges.map((blog, i) => {
                                                        return (
                                                            <ul className='wpp-list wpp-cards blog-ul' key={i}>
                                                                <li>
                                                                    <img
                                                                        width='45'
                                                                        height='45'
                                                                        src={
                                                                            blog &&
                                                                            blog.node &&
                                                                            blog.node.featuredImage &&
                                                                            blog.node.featuredImage &&
                                                                            blog.node.featuredImage.node &&
                                                                            blog.node.featuredImage.node.sourceUrl
                                                                        }
                                                                    />
                                                                    <div className='wpp-item-data'>
                                                                        <a
                                                                            className='wpp-post-title'
                                                                            target='_blank'
                                                                            href={
                                                                                popularBlog &&
                                                                                popularBlog.edges &&
                                                                                popularBlog.edges[0] &&
                                                                                popularBlog.edges[0].node &&
                                                                                popularBlog.edges[0].node.uri
                                                                            }
                                                                        >
                                                                            {blog.node.title}
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </aside>
                                    </div>
                                    <Pagination
                                        className='pagination-bar'
                                        currentPage={activePage}
                                        totalCount={
                                            blogPostWithoutNullContent && blogPostWithoutNullContent.length
                                        }
                                        pageSize={pageSize}
                                        onPageChange={(page) => setActivePage(page)}
                                    />
                                </div>
                            </div>
                        </section>
                    </>
                ) : page.uri === '/blogs/' && search !== '' && search !== undefined ? (
                    <>
                        <section id='content' className='main-blog-section'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className='row'>
                                            <div className='col-md-12 blog-col'>
                                                <article id='post-3288'>
                                                    {searchData && searchData.length > 0 ? (
                                                        searchData.map((blg, i) => {
                                                            return (
                                                                <div className='post-heading custom-heading' key={i}>
                                                                    <h5 className='blog-heading-title'>
                                                                        <a target='_blank' href={blg.node.uri}>
                                                                            {blg.node.title}
                                                                        </a>
                                                                    </h5>
                                                                    <h2 className='blog-heading-excerpt'>
                                                                        <a target='_blank' href={blg.node.uri}>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: blg.node.excerpt,
                                                                                }}
                                                                            ></div>
                                                                        </a>
                                                                    </h2>
                                                                </div>
                                                            );
                                                        })
                                                    ) : searchData && searchData.length === 0 ? (
                                                        <div>No Data Found</div>
                                                    ) : null}
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ) : null}

                {
                page.uri === '/contact-us/' ? (
                    <>
                        <TalkToUsSection />
                    </>
                ) : null
                }

                {/* {
                page.uri === '/our-team/' ? (
                    <>
                        <h1>hello team</h1>
                    </>
                ) : null
                } */}

                {page.uri === '/cultural-values/' ? (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                    </>
                ) : null}

                {
                page.uri === '/ai-case-study/' ||
                    page.uri === '/crm-case-study/' ? (
                        <>
                            <div className='postWrapper'>
                                <div className='postContentWrapper'>
                                    <div className='postContent'>
                                        <div dangerouslySetInnerHTML={{ __html: page.content }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }

                {
                    page.uri == '/case-study-list/' ?
                    <Case_all_study  allWpCategory={allWpCategory}/>
                    : null
                    
                }
            </Layout>
        );
};

export default PageTemplate;

export const PageQuery = graphql`
  query ($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          title
          sourceUrl
        }
      }
      template {
        ... on WpTemplate_Career {
          templateName
          careerContent {
            bottomBackgroundImage {
              backgroundDescription
              backgroundTitle
              fieldGroupName
              features {
                feature1 {
                  feature1Title
                  feature1Image {
                    sourceUrl
                  }
                }
                feature2 {
                  feature2Title
                  feature2Image {
                    sourceUrl
                  }
                }
                feature3 {
                  feature3Title
                  feature3Image {
                    sourceUrl
                  }
                }
              }
              backgroundImage {
                sourceUrl
              }
            }
            careerMainContent {
              description
              fieldGroupName
              subTitle
              title
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
    allWpEasytestimonial {
      edges {
        node {
          id
          title
          content
          externalLinks
          featuredImage {
            node {
              sourceUrl
              localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            }
          }
        }
      }
    }
    allWpPost(
        filter: {categories: {nodes: {elemMatch: {name: {eq: "Blogs"}}}}, , status: {eq: "publish"}}
    ) {
      edges {
        node {
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          content
          date(formatString: "DD MMMM YYYY, h:mm a")
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          title
          uri
        }
      }
    }
    allWpCategory(
      filter: { parentDatabaseId: { eq: 145 } }
      sort: { order: ASC, fields: databaseId }
    ) {
      edges {
        node {
          id
          name
          databaseId
        }
      }
    }
  }
`;
