import React from "react"
import { useListProductsQuery } from "../../hooks/useListProductsQuery"
import { StaticImage } from "gatsby-plugin-image"
//import ExternalLink from "../ExternalLink/externalLink"

import { imageBaseUrl } from '../../_helpers/constant'

const ProductsSection = () => {
  const { allWpPost } = useListProductsQuery();
  const listProducts = allWpPost.edges
  //console.log('list productss::: ',listProducts)

  return (
    <section className="section our-products-sec" id="section3">
      {/*- Start container */}
      <div className="container">
        {/*- Start Container */}
        <div className="row">
          {/*- Start row */}
          <div className="col-12 col-sm-12">
            <div className="section-heading-title">
              <h3> Products </h3>
            </div>
          </div>
        </div>
        {/*- end row */}
        <div className="row product_row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 pl-0 pr-0">
            <div className="our-products">
              <p>
                Our product portfolio enables customers to do business more
                efficiently and effectively and touch new levels of success. We
                are making your IT experience seamless while reducing the cost
                involved.
              </p>
              <ul className="product-accordian">
                {listProducts.map((listProduct) => {
                  //console.log('listProduct::: ',listProduct)
                  return (
                    <li key={listProduct.node.id}>
                      <a href={listProduct.node.id === "cG9zdDo3MjAw" ? "https://www.archiveon.cloud" : listProduct.node.uri} target="_blank">
                        {listProduct.node.title}<i className="icon-arrow-right">&nbsp;</i>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 pl-0 pr-0">
            <div className="row ml-0 mr-0">
              <div className="col-12 col-sm-12 col-md-6 pl-0  pr-0 bg-one  d-flex justify-content-center">
                <div className="product-box">
                  <figure>
                    <a
                      href={listProducts[0].node.uri}
                      target="_blank"
                    >

                      <StaticImage
                        width={300}
                        height={300}
                        src={'https://i2.wp.com/cms.webuters.com/wp-content/uploads/2020/03/Rent-App-1.png'}
                        alt=""
                      />

                    </a>
                  </figure>
                  <h5>{listProducts[0].node.title}</h5>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 pl-0  pr-0 bg-two  d-flex justify-content-center">
                <div className="product-box">
                  <figure>
                    <a
                    target="_blank"
                      href={listProducts[1].node.uri}
                    >

                      <StaticImage
                        width={300}
                        height={300}
                        src={'https://i2.wp.com/cms.webuters.com/wp-content/uploads/2018/09/EMS.png'}
                        alt="EMS"
                      />

                    </a>
                  </figure>
                  <h5>{listProducts[1].node.title}</h5>
                </div>
              </div>
            </div>
            <div className="row ml-0 mr-0">
              <div className="col-12 col-sm-12 col-md-6 pl-0  pr-0 bg-two  d-flex justify-content-center">
                <div className="product-box">
                  <figure>
                    <a 
                      target="_blank"
                      href="https://www.archiveon.cloud"
                    >

                      <StaticImage
                        width={300}
                        height={300}
                        src={'https://i2.wp.com/cms.webuters.com/wp-content/uploads/2020/02/Archive-on-cloud.png'}
                        className="image wp-image-8140  attachment-medium size-medium"
                        alt="Archive on cloud"
                      />

                    </a>
                  </figure>
                  <h5>{listProducts[2].node.title}</h5>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 pl-0  pr-0 bg-one  d-flex justify-content-center">
                <div className="product-box">
                  <figure>
                    <a
                      target="_blank"
                      href={listProducts[3].node.uri}
                    >

                      <StaticImage
                        width={300}
                        height={300}
                        src={'https://i2.wp.com/cms.webuters.com/wp-content/uploads/2020/02/Walk-CRM.png'}
                        className="image wp-image-8141  attachment-medium size-medium"
                        alt="Walk CRM"

                      />

                    </a>
                  </figure>
                  <h5>{listProducts[3].node.title}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*- end container */}
    </section>
  )
}
export default ProductsSection
