import React, { useState, ref } from "react"
//import { checkMandatory1438341000000328497 } from '../../../static/zohoform'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'; 
import { Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';



const TalkToUsSection = () => {
  const [first_name, setFirstName] = useState("")
  const [last_name, setlastName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [skypeId, setSkypeId] = useState("")
  const [message, setMessage] = useState("")

  const [isLoading, setIsloading] = useState(false)
  const style = {
    borderRadius: "10px",
    background: "#333",
    color: "#00FF00",
    padding: "10px",
    fontSize: "20px",
  };

  const defaultProps = {
      center: {
        lat: 28.610910,
        lng: 77.358710,
      },
      zoom: 11,
  }

  const handleChange = async (e) => {
    let name = e.target.name
    let value = e.target.value

    if(name === "first_name"){
      setFirstName(value)
    }
    if(name === "last_name"){
      setlastName(value)
    }
    if(name === "email"){
      setEmail(value)
    }
    if(name === "company"){
      setCompany(value)
    }
    if(name === "skypeId"){
      setSkypeId(value)
    }

    if(name === "message"){
      setMessage(value)
    }
  }
  const resetForm = () => {
    console.log('resettt')
    setFirstName("")
    setlastName("")
    setEmail("")
    setCompany("")
    setSkypeId("")
    setMessage("")
  }
  const handleSubmit = async(e) => {
    console.log('handleSubmit heree')
    console.log('handleSubmit state:: ', first_name)
    setIsloading(true)
    e.preventDefault()
    
    let obj = {
      "email": email,
      "first_name": first_name,
      "last_name": last_name,
      "company": company,
      "skypeId": skypeId,
      "message": message
    }
    let endPointUrl = `https://us-central1-webutersdotcom.cloudfunctions.net/sendEmail`;
    console.log("enpdPoint Url:: ", endPointUrl);
    const config = {
      method: "POST",
      url: endPointUrl,
      headers: {
        "Content-Type": "application/json"
      },
      data: obj,
    };
    let res = await axios(config);
    //console.log('ressssdd:: ',JSON.stringify(res.data))

    let data = res.data;
    console.log("data:::", JSON.stringify(data.data));
    setIsloading(false)
    resetForm()
    toast('Form submitted Successfully', {style: style})
    e.preventDefault()
  }

  
  return (
    <section
      className="talkUs-section parallax-window position-relative"
      data-parallax="scroll"
      id="section9"
    >

    <Toaster />
      {/*- Start Container */}
      <StaticImage src="../../images/webuters-map.png" className="contact-bg" alt=""/>
       
      <div className="contact-container">
        {isLoading ? 
      <Spinner animation="border" role="status" className="contact-loader">
        <span className="sr-only">Loading...</span>
      </Spinner>

      : null}

        <div className="container">
          {/*- Start Row */}
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="talkUs_form">
                <div className="head-title-gt">
                  <h2>CONTACT US </h2>
                  <div className="divider" />
                </div>
                <div id='crmWebToEntityForm' style={{ width: '100%', margin: 'auto' }}>
                  <meta http-equiv='content-type' content='text/html;charset=UTF-8' />
                  <form onSubmit={handleSubmit}>
                    <input type='text' style={{ display: 'none' }} name='xnQsjsdp' value='00b6a8f064efba6cb3df4d62005405ada55fb8d982cb5bd24bc5a51e47009096' />
                    <input type='hidden' name='zc_gad' id='zc_gad' value='' />
                    <input type='text' style={{ display: 'none' }} name='xmIwtLD' value='0533b6639c5935543f44cc5d250ea8933d4572be661e5430a15f92f1e7ae7228' />
                    <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
                    <input type='text' style={{ display: 'none' }} name='returnURL' value='http://www.webuters.com/thankyou/' />< br />

                    <input type='text' style={{ display: 'none' }} id='ldeskuid' name='ldeskuid' />
                    <input type='text' style={{ display: 'none' }} id='LDTuvid' name='LDTuvid' />

                    {/*<style>
         {{ 
            padding:'6px',
            border_spacing:'0px',
            border_width:'0px'
            }}
</style> */}
                    <table style={{ width: '100%', background_color: '#ffffff', background_color: 'white', color: 'black' }}>
                      <tr>
                        <td style={{ width: '100%' }}><div class='controls controls-row'><input type='text' value={company} style={{ width: '100%', box_sizing: 'border-box' }} maxlength='100' name='company' placeholder='Company*' onChange={handleChange} required /></div></td></tr>
                      <tr><td style={{ width: '50%' }} ><div class='controls controls-row'><input type='text' value={first_name} style={{ width: '100%', box_sizing: 'border-box' }} maxlength='40' name='first_name' placeholder='First Name*' onChange={handleChange} required/></div></td></tr>
                      <tr><td style={{ width: '50%' }} ><div class='controls controls-row'><input type='text' value={last_name} style={{ width: '100%', box_sizing: 'border-box' }} maxlength='80' name='last_name' placeholder='Last Name*' onChange={handleChange} required /></div></td></tr>
                      { /*<!-- <tr><td style='width:100%;' ><div class='controls controls-row'><input type='text' style='width:100%;box-sizing:border-box;'  maxlength='30' name='Phone' placeholder='Phone' /></div></td></tr> --> */}
                      <tr><td style={{ width: '100%' }} ><div class='controls controls-row'><input type='email' value={email} style={{ width: '100%', box_sizing: 'border-box' }} maxlength='30' name='email' placeholder='Email*' onChange={handleChange} required /></div></td></tr>
                      <tr><td style={{ width: '100%' }} ><div class='controls controls-row'><input type='text' value={skypeId} style={{ width: '100%', box_sizing: 'border-box' }} maxlength='50' name='skypeId' placeholder='Skype ID' onChange={handleChange} /></div></td></tr>
                      <tr><td style={{ width: '100%' }}><div class='controls controls-row'><textarea name='message' value={message} placeholder='Message' maxlength='32000' style={{ width: '100%', border_radius: '80px', color: '#b6b6b6', padding: '21px' }} onChange={handleChange}></textarea></div></td></tr>
                      <input type='hidden' name='Lead Source' value="Website" />
                      { /* <!-- <tr><td style='width:100%;'><div class='controls controls-row'>
<select style='width:100%;' name='Lead Source'>
<option value='-None-'>-Lead Source-</option>
<option value='Advertisement'>Advertisement</option>
<option value='Cold&#x20;Call'>Cold Call</option>
<option value='Employee&#x20;Referral'>Employee Referral</option>
<option value='External&#x20;Referral'>External Referral</option>
<option value='OnlineStore'>OnlineStore</option>
<option value='UPWORK'>UPWORK</option>
<option value='GURU'>GURU</option>
<option value='Partner'>Partner</option>
<option value='Freelancer'>Freelancer</option>
<option value='Public&#x20;Relations'>Public Relations</option>
<option value='Fivrr'>Fivrr</option>
<option value='Sales&#x20;Mail&#x20;Alias'>Sales Mail Alias</option>
<option value='Facebook'>Facebook</option>
<option value='Seminar&#x20;Partner'>Seminar Partner</option>
<option value='Twitter'>Twitter</option>
<option value='Seminar-Internal'>Seminar-Internal</option>
<option value='Trade&#x20;Show'>Trade Show</option>
<option value='Web&#x20;Download'>Web Download</option>
<option value='Web&#x20;Research'>Web Research</option>
<option value='Chat'>Chat</option>
</select></div></td></tr> --> */}
                      <tr className="row"><td colspan='2' className="col-12 d-flex justify-content-center" >
                        <input style={{ cursor: 'pointer' }} id='formsubmit' type='submit' class='btn btn btn-primary know-btn cnt-form-btn' value='Submit' disabled={isLoading}/>
                        <input type='reset' name='reset' style={{ cursor: 'pointer' }} class='btn btn btn-primary know-btn cnt-form-btn' value='Reset' />
                      </td></tr></table>
                  </form>
                </div>

              </div>
            </div>
          </div>
          {/*- End Row */}
        </div>
        {/*- End Container */}
      </div>

    </section>
  )
}

export default TalkToUsSection