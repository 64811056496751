import React from "react"
//import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { useListBlogsQuery } from "../../hooks/useListBlogsQuery"
//import ExternalLink from "../ExternalLink/externalLink"
//import { imageBaseUrl } from '../../_helpers/constant'

const BlogSection = () => {
  const { allWpPost } = useListBlogsQuery();
  const listBlogs = allWpPost.edges
  //console.log('Home Blogs', listBlogs)

  return (
    <section className="section blog-section" id="section8">
      <div className="container">
        {/*- Start Container */}
        <div className="row">
          {/*- Start Row */}
          <div className="col-12 col-sm-12">
            <div className="section-heading-title text-center">
              <h3> Our Blogs </h3>
              <p>
                {" "}
                We believe in sharing the knowledge and for this, we have our
                blog{" "}
              </p>
            </div>
          </div>
        </div>
        {/*- end row */}
      </div>
      {/*- end container */}
      {/*- main-blog  Container */}
      <div className="main-blog-container">
        <div className="container">
          <div className="row">
            {/*- row start */}
            <div className="col-12 col-sm-12">
              <div
                className="main_blog_block fadeIn"
                data-animation="fadeInBig"
              >
                <figure>
                  <a
                    href="/nativescript-vs-react-native-for-cross-platform-app-development-2-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage src='https://i2.wp.com/cms.webuters.com/wp-content/uploads/2020/01/main_blog_img-1.jpg' alt="" />
                  </a>
                </figure>
                <div className="main_blog_cont">
                  <a
                    href="/nativescript-vs-react-native-for-cross-platform-app-development-2-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h2>
                      {" "}
                      NativeScript Vs React Native For Cross-Platform App
                      Development{" "}
                    </h2>{" "}
                  </a>
                  <strong>19 June 2020, 3:35 pm</strong>
                  <p>
                    In the past, the development of cross-platform applications
                    was a taxing task for most developers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*- row end */}


          <div className="row">
            {/*- row start */}
            {listBlogs.map((listBlog) => {
                const image = getImage(listBlog.node.featuredImage.node.localFile)
              return (
                <div key={listBlog.node.id} className="col-12 col-sm-11 col-md-11 col-lg-6">
                  <div className="row inner_blog_box">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                      <a href={listBlog.node.uri} target="_blank">
                        <GatsbyImage image={image} alt={listBlog.node.featuredImage.node.title} />

                      </a>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                      <div className="inner_blog_cont">
                        <a href={listBlog.node.uri} target="_blank">
                          <h3> {listBlog.node.title} </h3>
                        </a>
                        <strong>{listBlog.node.date}</strong>
                        <div className="inner-service-box ellipsis" dangerouslySetInnerHTML={{ __html: listBlog.node.excerpt }} />
                        <p />
                      </div>
                    </div>
                  </div>
                </div>

              )
            })}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <a
                href="/blogs/"
                className="btn btn btn-primary know-btn"
                target="_blank"
                rel="noreferrer"
              >
                Know more
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*- end main-blog container */}
    </section>
  )
}

export default BlogSection
